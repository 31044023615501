import jQuery from "jquery";
import packageInfo from "../../package.json";

let version = packageInfo.version;

const sToken = "bb326e6a60e51ea332f02208ee39a1ed5a0543269ae79";
const sAuth = "GRwUN%OXby5(ZBdOupjy$2MJKYbOpaP#oiUs(610lrv8@GAhPTk^bzkHd$51$JI^";
const sAttachment = "&jshdgfy5=1";
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function generatePromise(oRequest) {
  return new Promise((resolve, reject) => {
    jQuery
      .ajax(oRequest)
      .done((oResponse, textStatus, jqXHR) => {
        if (jqXHR.status === 200) {
          resolve(oResponse);
        } else {
          reject(oResponse);
        }
      })
      .fail(jqXHR => {
        reject(jqXHR.status);
      });
  });
}

function generateFetchPromise(oRequest, oSignal) {
  return fetch(oRequest.url, {
    method: oRequest.method,
    headers: oRequest.headers,
    body: oRequest.data,
    signal: oSignal
  })
  .then(response => {
    if (response.status === 204) {
      throw new Error(204)
    }
    return response;
  });
}

const ApiCalls = {
  // Messages select conversation
  getMessages: (
    sHost,
    sPhone,
    sSource,
    bFilter,
    bDeveloper,
    bCache,
    sCompany,
    sUnit,
    sEmail,
    sEncryptionKey,
    oSignal
  ) => {
    if (!bCache) {
      bCache = false;
    }
    let sUrl = `${sHost}/v3/getmessages?phone=${sPhone}&assesor_email=${sEmail}&order=asc&cache=${bCache}&developer=${bDeveloper}&array=true&company=${sCompany}&unit=${sUnit}&version=${version}&encryptionkey=${sEncryptionKey}&origin=web`;
    if (typeof bFilter === "undefined" || bFilter) {
      sUrl += "&source=" + sSource.toLowerCase();
    }

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };

    return generateFetchPromise(oRequest, oSignal);
  },

  getMessagesScrollAPI: (sHost, sPhone, sSource, sCompany, sEmail, dateMessageTop, sEncryptionKey) => {
    let sUrl = `${sHost}/v3/scroll/getmessages?phone=${sPhone}&order=asc&from=${dateMessageTop}&source=${sSource}&company=${sCompany}&assesor_email=${sEmail}&version=${version}&encryptionkey=${sEncryptionKey}&origin=web`;

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };

    return generateFetchPromise(oRequest);
  },

  // Message list schedule
  getMessagesSchedule: (sHost, sPhone, sAssessor, sSource, sCompany) => {
    let sUrl = `${sHost}/v3/schedule/get?to=${sPhone}&assesor_email=${sAssessor}&version=${version}&origin=web&source=${sSource}&company=${sCompany}`;

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };

    return generateFetchPromise(oRequest);
  },

  //Update message schedule
  updateMessageSchedule: (sHost, ubmId, date_modified, sSource, sCompany) => {
    let sUrl = `${sHost}/v3/schedule/update?ubm_id=${ubmId}&at=${date_modified}&source=${sSource}&company=${sCompany}&version=${version}&origin=web`;

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };

    return generateFetchPromise(oRequest);
  },

  // Delete message schedule
  deleteMessageSchedule: (sHost, ubmId, sSource, sCompany) => {
    let sUrl = `${sHost}/v3/schedule/delete?ubm_id=${ubmId}&source=${sSource}&company=${sCompany}&version=${version}&origin=web`;

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };

    return generateFetchPromise(oRequest);
  },

  // Send message
  sendMessageAPI: (sHost, sMessage, oProps) => {
    let oRequest = {
      url: `${sHost}/v3/send`,
      method: "POST",
      headers: {
        "X-Auth": sAuth,
        token: sToken,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        to: oProps.phone,
        custom_uid: "",
        text: sMessage,
        sid: "",
        newtest: "Y",
        token: sToken,
        idcurso: oProps.course_id,
        source: oProps.source,
        client_name: oProps.client_name,
        client_lastname: oProps.client_lastname,
        client_email: oProps.client_email,
        country: oProps.country ?? "",
        nombre_curso: oProps.course_name,
        assesor_data_first_name: oProps.assessor_name,
        assesor_data_last_name: oProps.assessor_lastname,
        assesor_data_email: oProps.assessor_email,
        language: oProps.language ?? "",
        developer: oProps.developer,
        company: oProps.company,
        unit: oProps.unit,
        groupid: oProps.groupid,
        at: oProps.at,
        version: version,
        origin: "web",
        ubm_id: oProps.ubm_id,
        default_message: typeof oProps.selectedDefaultMessage != 'undefined' ? oProps.selectedDefaultMessage : null,
        track: typeof  oProps.track != 'undefined' &&  oProps.track?.length > 0 ? oProps.track : ""
      })
    };
    return generateFetchPromise(oRequest);
  },

  // Create room video call
  addRoomVideoCall: oProps => {
    let sUrl = `${oProps.host}/v3/video/addroom`;
    let oRequest = {
      url: sUrl,
      method: "POST",
      headers: {
        "X-Auth": sAuth,
        token: sToken,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        to: oProps.phone,
        idcurso: oProps.course_id,
        client_name: oProps.client_name,
        client_lastname: oProps.client_lastname,
        client_email: oProps.client_email,
        assesor_data_first_name: oProps.assesor_name,
        assesor_data_last_name: oProps.assesor_lastname,
        assesor_data_email: oProps.assesor_email,
        country: oProps.country,
        language: oProps.language,
        source: oProps.source,
        company: oProps.company,
        developer: oProps.developer,
        audioOnly: oProps.audioOnly,
        version: version,
        origin: "web"
      })
    };

    return generatePromise(oRequest);
  },

  getMqttData: oProps => {
    const data = JSON.stringify({
      assesor_email: oProps.assesor_email,
      source: oProps.source,
      company: oProps.company,
      clientid: oProps.clientId,
      timezone: timezone
    });
    const sUrl = `${oProps.host}/v3/ws/add`;
    const oRequest = {
      url: sUrl,
      method: "POST",
      headers: {
        "X-Auth": sAuth,
        token: sToken,
        "Content-Type": "application/json"
      },
      data: data
    };

    return generateFetchPromise(oRequest);
  },

  disconnectedMQTT: oProps => {
    const data = JSON.stringify({
      assesor_email: oProps.assesor_email,
      source: oProps.source,
      company: oProps.company,
      clientid: oProps.clientId,
      timezone: timezone,
      details: oProps.details
    });
    const sUrl = `${oProps.host}/v3/ws/disconnected`;
    const oRequest = {
      url: sUrl,
      method: "POST",
      headers: {
        "X-Auth": sAuth,
        token: sToken,
        "Content-Type": "application/json"
      },
      data: data
    };

    return generateFetchPromise(oRequest);
  },

  getAccessTokenTwilioVideo: (sHost, identity, roomName, uniq, sSource, sCompany) => {
    let sUrl = `${sHost}/v3/video/gettoken?identity=${identity}&room=${roomName}&uniq=${uniq}&source=${sSource}&company=${sCompany}&version=${version}&origin=web`;

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };

    return generatePromise(oRequest);
  },

  // Configuration client
  getClientConfig: (sHost, sCompany, sSource) => {
    const sUrl = `${sHost}/v3/settings/getclientconfig`;
    let oData = {
      version: version,
      origin: "web"
    };

    if (sCompany) {
      oData.company = sCompany;
    } else {
      oData.source = sSource.includes(",") ? sSource.substring(0, sSource.indexOf(",")) : sSource;
    }

    let oRequest = {
      url: sUrl,
      headers: {
        masterkey: sToken,
        "Content-Type": "application/json"
      },
      method: "POST",
      data: JSON.stringify(oData)
    };

    return generateFetchPromise(oRequest);
  },

  getAttachmentsThumbnailAPI: sUrl => {
    let sUrlCompound = `${sUrl}${sAttachment}&thumbnail=true&timezone=${timezone}`;
    let oRequest = {
      url: sUrlCompound,
      method: "GET"
    };

    return generateFetchPromise(oRequest);
  },

  // Get Attachments
  getAttachmentsAPI: sUrl => {
    let sUrlCompound = `${sUrl}${sAttachment}&timezone=${timezone}`;
    let oRequest = {
      url: sUrlCompound,
      method: "OPTIONS"
    };

    return new Promise(resolve => {
      jQuery.ajax(oRequest).done(() => {
        resolve(sUrlCompound);
      });
    });
  },

  // Validate phone client
  validatePhoneAPI: (sPhone, sCountry, sCompany, sUnit, sHost) => {
    const oRequest = {
      url: `${sHost}/v3/hlr/?token=${sToken}&number=${sPhone}&country=${
        sCountry ?? ""
      }&company=${sCompany}&unit=${sUnit}&version=${version}&origin=web`,
      method: "GET"
    };

    return new Promise((resolve, reject) => {
      if (sPhone) {
        oRequest.success = oResponse => {
          if (oResponse && oResponse.hlrstatus === "ok") {
            resolve({
              phone: oResponse.internationalnumber,
              country: oResponse.country
            });
          } else {
            reject("Invalid phone number format");
          }
        };
        oRequest.error = err => {
          reject(err);
        };
        jQuery.ajax(oRequest);
      } else {
        reject("Phone number is mandatory");
      }
    });
  },

  // User sources
  getSources: oProps => {
    const { host, company } = oProps;
    let sUrl = `${host}/v3/settings/getsources?version=${version}&origin=web`
    if (typeof company != 'undefined' && company == 'undefined' && company.length > 0)  {
      sUrl = sUrl+`&company=${company}`;
    }
    
    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };

    return generateFetchPromise(oRequest);
  },

  // Refresh User List
  getUsers: (sHost, sEmail, bDeveloper, bCache, sSource, sCompany, sUnit, sEncryptionKey) => {
    if (!bCache) {
      bCache = false;
    }

    let sUrl = `${sHost}/v3/getmessagesbyemail?assessor_email=${sEmail}&source=${sSource}&cache=${bCache}&developer=${bDeveloper}&array=true&company=${sCompany}&timezone=${timezone}&unit=${sUnit}&version=${version}&encryptionkey=${sEncryptionKey}&origin=web`;

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };
    return generateFetchPromise(oRequest);
  },

  // Mark as read
  markReadMessages: (sHost, sPhone, sSource, sCourseId, sCompany, sUnit, sEmail) => {
    let oRequest = {
      url: `${sHost}/v3/updateread`,
      method: "POST",
      headers: {
        token: sToken,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        phone: sPhone,
        course_id: sCourseId,
        source: sSource,
        token: sToken,
        company: sCompany,
        unit: sUnit,
        assesor_email: sEmail,
        version: version,
        origin: "web"
      })
    };

    return generateFetchPromise(oRequest);
  },

  // Mark as unread
  markAsUnread: (sHost, sEmail, sPhone, sSource, sCompany) => {
    let oRequest = {
      url: `${sHost}/v3/useractions/markasunread`,
      method: "POST",
      headers: {
        token: sToken,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        assesor_email: sEmail,
        phone: sPhone,
        source: sSource,
        company: sCompany,
        version: version,
        origin: "web"
      })
    };

    return generateFetchPromise(oRequest);
  },

  // Create group
  addGroup: (sHost, aoMembers, sGroupName, sSource, sComapny) => {
    let oRequest = {
      url: `${sHost}/v3/groups/addgroup`,
      method: "POST",
      headers: {
        token: sToken,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        source: sSource,
        groupname: sGroupName,
        members: aoMembers,
        version: version,
        origin: "web",
        timezone: timezone,
        company: sComapny
      })
    };

    return generatePromise(oRequest);
  },

  // Delete message from conversation
  deleteMessage: (sHost, ubmId, source, company) => {
    let oRequest = {
      url: `${sHost}/v3/delete`,
      method: "POST",
      headers: {
        token: sToken,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        ubm_id: ubmId,
        source: source,
        company: company,
        version: version,
        origin: "web"
      })
    };

    return generatePromise(oRequest);
  },

  getUserContacts: (sHost, sEmail, bDeveloper, sSource, sValue, sCompany, sUnit) => {
    if (!sSource) {
      sSource = "SF";
    }

    let sUrl = `${sHost}/v3/getcontactsbyemail?assessor_email=${sEmail}&token=${sToken}&source=${sSource}&developer=${bDeveloper}&company=${sCompany}&unit=${sUnit}&query=${sValue}&array=true&version=${version}&origin=web`;

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };

    return generateFetchPromise(oRequest);
  },

  getAssessors: (sHost, sSource, sCompany, sValue) => {
    if (!sSource) {
      sSource = "SF";
    }

    let sUrl = `${sHost}/v3/getassesorsbysource?token=${sToken}&query=${sValue}&source=${sSource}&company=${sCompany}&version=${version}&origin=web`;

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };

    return generateFetchPromise(oRequest);
  },

  // List of substitutes for advisors
  getUserDelegate: (sHost, sEmail, bDeveloper, sSource, sCompany, sUnit) => {
    let sUrl = `${sHost}/v3/getsubstitutes?assessor_email=${sEmail}&source=${sSource}&array=true&developer=${bDeveloper}&company=${sCompany}&unit=${sUnit}&version=${version}&origin=web`;

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };

    return generateFetchPromise(oRequest);
  },

  getUserSettings: oProps => {
    const { assessor_email: sEmail, host: sHost, source: sSource, company: sComapny } = oProps;
    let sUrl = `${sHost}/v3/settings/get?assessor_email=${sEmail}&version=${version}&origin=web&source=${sSource}&company=${sComapny}`;

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };

    return generateFetchPromise(oRequest);
  },

  // Information chat group
  getInfoGroup: (sHost, sSource, sCompany, sGroupid) => {
    let sUrl = `${sHost}/v3/groups/getgroup?source=${sSource}&company=${sCompany}&groupid=${sGroupid}&version=${version}&origin=web`;

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };

    return generatePromise(oRequest);
  },

  getContactDetails: (sHost, sPhone, sSource, bCache, sCompany, sEmail, exception = false) => {
    if (!bCache) {
      bCache = false;
    }
    let sUrl = `${sHost}/v3/getcontactdetails?assessor_email=${sEmail}&company=${sCompany}&source=${sSource.toLowerCase()}&phone=${sPhone}&cache=${bCache}&origin=web`;
    if (exception) {
      sUrl += '&exception=true'
    }
    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };

    return generateFetchPromise(oRequest);
  },

 

  // User settings
  saveUserSettings: (sEmail, oSettings) => {
    let parseUserSaveSettings = (oAppConfig) => {
      let oSettings = {
        size: oAppConfig.size,
        source: oAppConfig.source,
        width: oAppConfig.width,
        height: oAppConfig.height,
        minimized: oAppConfig.minimized,
        dark_theme: oAppConfig.darkTheme,
        default_messages: oAppConfig.default_messages,
        version: version,
        host: oAppConfig.host,
        userTags: oAppConfig.userTags,
        usetags: oAppConfig.usetags,
        currentTabId: oAppConfig.currentTabId,
        expandedTagsMenu: oAppConfig.expandedTagsMenu,
        notifications: typeof oAppConfig.notifications != 'undefined' ? oAppConfig.notifications : false,
        defaultCompanyMessagesfilters: typeof oAppConfig.defaultCompanyMessagesfilters != 'undefined' ? oAppConfig.defaultCompanyMessagesfilters : []
      };
      return oSettings;
    }

    let parsedSettings = parseUserSaveSettings(oSettings);


    let oRequest = {
      url: `${parsedSettings.host}/v3/settings/save`,
      method: "POST",
      headers: {
        "X-Auth": sAuth,
        token: sToken,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        assessor_email: sEmail,
        config: parsedSettings,
        version: version,
        origin: "web"
      })
    };

    return generateFetchPromise(oRequest);
  },

  postActivateDeactivateGroup: (sHost, groupid, owner, active, source, company) => {
    let sUrl = `${sHost}/v3/groups/deactivategroup`;

    let oRequest = {
      url: sUrl,
      method: "POST",
      headers: {
        "X-Auth": sAuth,
        token: sToken,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        groupid: groupid,
        owner: owner,
        active: active,
        source: source,
        company: company,
        version: version,
        origin: "web"
      })
    };

    return generatePromise(oRequest);
  },

  addNotificationToken: (sHost, email, token, source, company) => {
    let sUrl = `${sHost}/v3/settings/addnotifywebtoken`;

    let oRequest = {
      url: sUrl,
      method: "POST",
      headers: {
        "X-Auth": sAuth,
        token: sToken,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        assessor_email: email,
        notify_token: token,
        source: source,
        company: company,
        version: version,
        origin: "web",
        referer: window.location.href
      })
    };

    return generatePromise(oRequest);
  },

  postLeaveGroup: (sHost, groupid, email, source, company) => {
    let sUrl = `${sHost}/v3/groups/removecontact`;

    let oRequest = {
      url: sUrl,
      method: "POST",
      headers: {
        "X-Auth": sAuth,
        token: sToken,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        groupid: groupid,
        email: email,
        version: version,
        source: source,
        company: company,
        origin: "web"
      })
    };

    return generatePromise(oRequest);
  },

  toBase64: function (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.replace(/^data:.+;base64,/, ""));
      reader.onerror = error => reject(error);
    });
  },

  sendAttachment: async function (
    sHost,
    sPhone,
    sCourseId,
    sText,
    sSource,
    sAssessor,
    developer,
    sCompany,
    sUnit,
    oFile,
    sMessageUUID,
    assesor_first_name = '',
    assesor_last_name = '',
    force_out_phone = '',
  ) {
    let formatFile = await this.toBase64(oFile);

    let sUrl = `${sHost}/v3/sendattach`;

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken,
        "X-Auth": sAuth,
        "Content-Type": "application/json"
      },
      method: "POST",
      data: JSON.stringify({
        to: sPhone,
        course_id: sCourseId,
        text: sText ? sText : "",
        source: sSource,
        assesor_data_email: sAssessor,
        token: sToken,
        developer: developer,
        company: sCompany,
        unit: sUnit,
        attach: formatFile,
        version: version,
        origin: "web",
        ubm_id: sMessageUUID,
        assesor_first_name: assesor_first_name,
        assesor_last_name: assesor_last_name,
        force_out_phone: force_out_phone //last message's twilio phone
      })
    };

    return generateFetchPromise(oRequest);
  },

  //get files attached in a conversation
  postMediaUser: (sHost, sEmail, sSource, sCompany, sType, sPhone) => {
    let sUrl = `${sHost}/v3/media/get`;

    let oRequest = {
      url: sUrl,
      method: "POST",
      headers: {
        token: sToken,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        assesor_email: sEmail,
        source: sSource,
        company: sCompany,
        type: sType,
        order: "asc",
        phone: sPhone
      })
    };

    return generatePromise(oRequest);
  },

  postDefaultAudio: async function (sHost, email, message, attach, source, company) {
    let sUrl = `${sHost}/v3/settings/saveaudiotemplate`;

    let formatFile = await this.toBase64(attach);

    let oRequest = {
      url: sUrl,
      method: "POST",
      headers: {
        token: sToken,
        "X-Auth": sAuth,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        assessor_email: email,
        message: message,
        attach: formatFile,
        source: source,
        company: company
      })
    };

    return generatePromise(oRequest);
  },

  removeAudioTemplate: (sHost, email, ubmId, sSource, sCompany) => {
    let sUrl = `${sHost}/v3/settings/removeaudiotemplate`;

    let oRequest = {
      url: sUrl,
      method: "POST",
      headers: {
        "X-Auth": sAuth,
        token: sToken,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        assessor_email: email,
        ubm_id: ubmId,
        source: sSource,
        company: sCompany,
        version: version,
        origin: "web"
      })
    };

    return generatePromise(oRequest);
  },

  updateGroup: (sHost, displayname, groupid, members, sSource, sCompany) => {
    let sUrl = `${sHost}/v3/groups/updategroup`;

    let oRequest = {
      url: sUrl,
      method: "POST",
      headers: {
        "X-Auth": sAuth,
        token: sToken,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        displayname: displayname,
        groupid: groupid,
        members: members,
        source: sSource,
        company: sCompany
      })
    };

    return generatePromise(oRequest);
  },

  globalSearch: (sHost, assessor_email, source, company, search) => {
    let sUrl = `${sHost}/v3/search/global`;

    let oRequest = {
      url: sUrl,
      method: "POST",
      headers: {
        "X-Auth": sAuth,
        token: sToken,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        assessor_email,
        source,
        company,
        search,
        version,
        origin: "web"
      })
    };

    return generatePromise(oRequest);
  },

  messageSearch: (sHost, assessor_email, source, company, ubm_id) => {
    let sUrl = `${sHost}/v3/search/arroundmessage?array=true`;

    let oRequest = {
      url: sUrl,
      method: "POST",
      headers: {
        "X-Auth": sAuth,
        token: sToken,
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        assessor_email,
        source,
        company,
        ubm_id,
        version,
        origin: "web"
      })
    };

    return generatePromise(oRequest);
  },

  // Get Company Tags
  getCompanyTags: (sHost, sSource, sCompany) => {
    let sUrl = `${sHost}/v3/tags/getcompanytags?source=${sSource}&company=${sCompany}`;

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };

    return generateFetchPromise(oRequest);
  },

  // Modify conversation tags
  updateConversationTags: (sHost, sSource, sComapny, sAssessor, oConversation) => {
    let sUrl = `${sHost}/v3/tags/updatetagstoconversation`;

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken,
        "Content-Type": "application/json"
      },
      method: "POST",
      data: JSON.stringify({
        assesor_email: sAssessor,
        companytags: oConversation.tags.company_tags,
        usertags: oConversation.tags.user_tags,
        source: sSource,
        company: sComapny,
        client_phone: oConversation.phone
      })
    };

    return generateFetchPromise(oRequest);
  },

  // Modify conversation tags
  deleteConversationTag: (sHost, sSource, sCompany, sAssessor, sTagKey) => {
    let sUrl = `${sHost}/v3/tags/removetagsfromconversation`;

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken,
        "Content-Type": "application/json"
      },
      method: "POST",
      data: JSON.stringify({
        assesor_email: sAssessor,
        usertags: [sTagKey],
        source: sSource,
        company: sCompany
      })
    };

    return generateFetchPromise(oRequest);
  },

  getLastWebComponentVersion: (sHost) => {
    let sUrl = `${sHost}/v3/utils/getlastwebcomponentversion?version=${version}&origin=web`;

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };

    return generateFetchPromise(oRequest);
  },

  getSystemNotifications: (sHost) => {
    let sUrl = `${sHost}/v3/utils/getsystemnotifications?version=${version}&origin=web&timezone=${timezone}`;

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };

    return generateFetchPromise(oRequest);
  },

  getDefaultMessages: (sHost, sSource, sCompany) => {
    let sUrl = `${sHost}/v3/getdefaultmessages?version=${version}&origin=web&timezone=${timezone}&source=${sSource}`;
    if (sCompany) {
      sUrl += `&company=${sCompany}`;
    }

    let oRequest = {
      url: sUrl,
      headers: {
        token: sToken
      },
      method: "GET"
    };

    return generateFetchPromise(oRequest);
  },
};

export default ApiCalls;

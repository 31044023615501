import React, { useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ApiCalls from "../../../utils/APIRequests";
import AppContext from "../../../AppContext";
import styles from "../../Chat/Customisation.module.css";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const DialogScheduleInputChat = ({
  openDialogSchedule,
  handleCloseDialogSchedule,
  inputMessage,
  setInputMessage,
  setCacheUsers,
  ubm_id
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { client, assessor, appConfig, setAppConfig, debugApp, setMessagesSchedule, selectedDefaultMessage } =
    useContext(AppContext);

  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const refreshMessagesSchedule = () => {
    setTimeout(() => {
      ApiCalls.getMessagesSchedule(appConfig.host, client.phone, assessor.email, appConfig.source, appConfig.company).then(
        async response => {
          let aoMessagesSchedule = await response.json();
          setMessagesSchedule(aoMessagesSchedule.reverse());
        }
      );
    }, 1000);
  };

  const handleOnSubmit = () => {
    if (ubm_id) {
      ApiCalls.updateMessageSchedule(appConfig.host, ubm_id, selectedDate.toISOString(), appConfig.source, appConfig.company).then(
        () => {
          refreshMessagesSchedule();
          handleCloseDialogSchedule();
        }
      );
    } else {
      if (client.phone && inputMessage) {
        const sSource = appConfig.source.split(",")[0];
        const oSendMessageAPIData = {
          phone: client.phone,
          source: sSource,
          client_name: client.name,
          client_lastname: client.lastName,
          client_email: client.email,
          country: client.country,
          course_name: client.courseName,
          course_id: client.courseId,
          assessor_name: assessor.name,
          assessor_lastname: assessor.lastName,
          assessor_email: assessor.email,
          developer: debugApp.developer,
          language: client.language ?? appConfig.language,
          company: client.company ? client.company : appConfig.company,
          unit: client.unit ? client.unit : appConfig.unit,
          groupid: client.groupid,
          at: selectedDate.toISOString(),
          selectedDefaultMessage: selectedDefaultMessage,
          track: client.track ?? ''
        };
        ApiCalls.sendMessageAPI(appConfig.host, inputMessage, oSendMessageAPIData)
          .then(() => {
            setAppConfig(appConfig => {
              return {
                ...appConfig,
                scheduleScreen: true
              };
            });
            refreshMessagesSchedule();
            setInputMessage("");
            setCacheUsers(false);
          })
          .catch(() => {});
        handleCloseDialogSchedule();
      }
    }
  };

  return (
    <Dialog
      open={openDialogSchedule}
      onClose={handleCloseDialogSchedule}
      id={appConfig.darkTheme ? styles["proeduca-messenger-dark"] : styles["proeduca-messenger"]}
    >
      <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
        {t("sendThisMessageOn")}
      </DialogTitle>
      <DialogContent className={appConfig.darkTheme ? classes.dialogDark : ""}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy HH:mm"
            margin="normal"
            value={selectedDate}
            onChange={handleDateChange}
            minDate={new Date()}
          />
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions className={appConfig.darkTheme ? classes.dialogDark : ""}>
        <Button onClick={handleCloseDialogSchedule} color="primary">
          <Typography variant="caption" color="primary">
            {t("cancel")}
          </Typography>
        </Button>
        <Button
          onClick={handleOnSubmit}
          className={classes.buttonHandleCloseDialog}
          color="primary"
        >
          <Typography variant="caption" color="secondary">
            {t("submit")}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogScheduleInputChat;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  dialogTitle: {
    backgroundColor: "#065d54",
    color: "#fff"
  },
  dialogDark: {
    backgroundColor: "#3e3e42",
    color: "#fff"
  },
  inputDark: {
    color: "#fff"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 270
  },
  buttonHandleCloseDialog: {
    backgroundColor: "#065d54",
    padding: "0.5rem 0.8rem",
    "&:hover": {
      backgroundColor: "#207060",
      opacity: [0.9, 0.8, 0.7]
    }
  }
}));

DialogScheduleInputChat.propTypes = {
  openDialogSchedule: PropTypes.bool,
  handleCloseDialogSchedule: PropTypes.func,
  inputMessage: PropTypes.string,
  setInputMessage: PropTypes.func,
  setCacheUsers: PropTypes.func,
  sendAttachments: PropTypes.func,
  micRecording: PropTypes.shape({
    isRecording: PropTypes.bool,
    blobURL: PropTypes.string,
    blob: PropTypes.object,
    isBlocked: PropTypes.bool
  }),
  setMicRecording: PropTypes.func,
  ubm_id: PropTypes.string
};

import React, { useContext, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Schedule, Send } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";

import {
	createTheme,
	IconButton,
	InputBase,
	ThemeProvider,
	Tooltip,
	Typography,
	Snackbar
} from "@material-ui/core";

import AppContext from "../../AppContext";
import ApiCalls from "../../utils/APIRequests";
import { DropzoneDialog } from "material-ui-dropzone";
import Helper from "../../utils/Helper";
import PopoverInputChat from "./Modals/PopoverInputChat";
import DialogScheduleInputChat from "./Modals/DialogScheduleInputChat";
import EmojiButton from "./Buttons/EmojiButton";
import PopoverEmoji from "./Modals/PopoverEmoji";
import ScheduleMessageButton from "./Buttons/ScheduleMessageButton";
import AttachmentButton from "./Buttons/AttachmentButton";
import MicButton from "./Buttons/MicButton";
import CancelMicButton from "./Buttons/CancelMicButton";
import MicRecorder from "mic-recorder-to-mp3";
import MessagesTemplateButton from "./Buttons/MessagesTemplateButton";
import DialogGlobalSearch from "../Dialogs/GlobalSearch/DialogGlobalSearch";
import DialogTemplateDefaultMessages from "./DefaultMessages/DialogTemplateDefaultMessages";
import DialogDefaultMessages from "../Dialogs/DefaultMessages/DialogDefaultMessages";
import { Alert } from "@material-ui/lab";
// import AlertTitleNotification from "../Alert/AlertTitleNotification";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const InputChat = ({ setCacheUsers }) => {
	const nLimitHours = 24;
	const classes = useStyles();
	const { t } = useTranslation();
	const { constants } = Helper;
	const { messages, 
		setMessages, 
		messagesSchedule, 
		client, 
		assessor, 
		appConfig, 
		debugApp, 
		updateComponent, 
		setUpdateComponent,
		setDisplayDefaultMessagesModal,
		setInputChatText,
		setSelectedDefaultMessage,
		selectedDefaultMessage
	 } =
		useContext(AppContext);

	const theme = createTheme({
		overrides: {
			MuiPaper: {
				root: {
					backgroundColor: "#2d2d30"
				}
			},
			MuiDropzoneArea: {
				root: {
					backgroundColor: "#3e3e42"
				},
				icon: {
					color: "#fff"
				}
			},
			MuiTypography: {
				root: {
					color: "#fff"
				}
			},
			MuiButton: {
				root: {
					"&.Mui-disabled": {
						color: "#b5b5b5"
					}
				}
			}
		},
		palette: {
			primary: {
				main: appConfig.darkTheme ? "#fff" : "#000"
			}
		}
	});

	const [inputMessage, setInputMessage] = useState("");

	// Propiedades de los adjuntos
	const [disabledButtonAttachment, setDisabledButtonAttachment] = useState(false);
	const [attachmentDialogOpen, setAttachmentDialogOpen] = useState(false);
	const [attachmentDialogOpenInitial, setAttachmentDialogOpenInitial] = useState(false);
	const [pasteFile, setPasteFile] = useState([]);

	// Propiedades del grabar audio
	const [micRecording, setMicRecording] = useState({
		isRecording: false,
		blobURL: "",
		blob: undefined,
		isBlocked: false
	});

	// Properties popover
	const [anchorElOptionsInputChat, setAnchorElOptionsInputChat] = React.useState(null);
	const bOpenOptionsInputChat = Boolean(anchorElOptionsInputChat);
	const idOptionsInputChat = bOpenOptionsInputChat ? "simple-popover" : undefined;

	// Propiedades del dialogo de mensajes programados
	const [openDialogSchedule, setOpenDialogSchedule] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const popoverId = open ? "emoji-popover" : undefined;

	// Propiedades del dialogo de mensajes predefinidos
	const [openDialogDefaultTemplates, setOpenDialogDefaultTemplates] = useState(false);

	// Propiedades del modal del selector de mensajes predeterminados
	const [openDialogDefaultMessages, setOpenDialogDefaultMessages] = useState(false);

	// Mensaje de error de máximo de carateres permitidos
	const [openNotification, setOpenNotification] = useState(false);
	const [toastTitle, setToastTitle] = useState("");
	const [toastMessage, setToastMessage] = useState("");

	const sendMessageTriggered = useRef(false);

	/*
	  Envolvemos el dialog del dropzone en un themeProvider para modificar los estilos del dialogo
	*/

	const handleClosePopoverOptionsInputChat = () => {
		setAnchorElOptionsInputChat(null);
	};

	// Funciones del dialogo de mensajes programados
	const handleCloseDialogSchedule = () => {
		setOpenDialogSchedule(false);
	};

	const goToScheduleMessages = () => {
		setOpenDialogSchedule(true);
	};

	const handleOpenPopover = event => {
		event.preventDefault();
		if (inputMessage !== "") {
			setAnchorElOptionsInputChat(event.currentTarget);
		}
	};

	// Envio de un mensaje
	const sendMessage = async oEvent => {
		if (micRecording.blobURL) {
			const file = new File([micRecording.blob], "audio_" + assessor.email);
			file.mimeType = 'audio/mpeg';

			sendAttachments([file]);
			setMicRecording({
				blob: undefined,
				isBlocked: false,
				blobURL: "",
				isRecording: false
			});
		} else {
			oEvent.preventDefault();
			if (client.phone && inputMessage && sendMessageTriggered.current == false) {
				sendMessageTriggered.current = true;
				let sSource = appConfig.source.split(",")[0];
				let sMessageUUID = uuidv4();
				let oSendMessageAPIData = {
					phone: client.phone,
					source: sSource,
					client_name: client.name,
					client_lastname: client.lastName,
					client_email: client.email,
					country: client.country,
					course_name: client.courseName,
					course_id: client.courseId,
					assessor_name: assessor.name,
					assessor_lastname: assessor.lastName,
					assessor_email: assessor.email,
					developer: debugApp.developer,
					language: client.language ?? appConfig.language,
					company: client.company ? client.company : appConfig.company,
					unit: client.unit ? client.unit : appConfig.unit,
					groupid: client.groupid,
					ubm_id: sMessageUUID,
					selectedDefaultMessage: selectedDefaultMessage,
					track: client.track ?? ''
				};

				const inputMessageAux = inputMessage;
				setInputMessage("");
				setCacheUsers(false);
				ApiCalls.sendMessageAPI(appConfig.host, inputMessageAux, oSendMessageAPIData)
					.then(() => {
						setMessages([
							...messages,
							{
								text: inputMessageAux,
								user: client.name,
								date_modified: new Date().toISOString(),
								messagestatus: "sent",
								ubm_id: sMessageUUID
							}
						]);
						setSelectedDefaultMessage(null);
						setTimeout(() => {
							const scroll = document.getElementById("messagesContainer");
							if (scroll && scroll.scrollHeight) scroll.scrollTop = scroll.scrollHeight;
						}, 1000);
					})
					.catch((err) => {
						if (appConfig.versbose) {
							console.log('InputChat.js -> sendMessage catch:', err);
						}
						setInputMessage(inputMessageAux);
						setErrorMessageArr(errMsgArr => [
							errMsgArr,
							{
							  key: "send_message",
							  code: 9,
							  message: 'Se ha producido un error al enviar el mensaje.',
							  value: null
							}
						]);
					})
					.finally(()=>{
						sendMessageTriggered.current = false;
					})
			}
		}
	};

	// Envio de adjuntos
	const sendAttachments = aoFiles => {
		const sText = "";
		let aoPromises = [];
		let sConversationId = localStorage.getItem("userKey");

		aoFiles.forEach(oFile => {
			let fileType = undefined;
			if (typeof oFile.mimeType != 'undefined') {
				fileType = oFile.mimeType;
				delete oFile.mimeType;
			}

			let sMessageUUID = uuidv4();

			let oNewMessage = {
				assesor_email: assessor.email,
				ubm_id: sMessageUUID,
				source: appConfig.source,
				date_modified: new Date().toISOString(),
				company: client.company ? client.company : appConfig.company,
				text: sText,
				messagestatus: "accepted",
				mime: "image/png",
				conversationid: sConversationId,
				attach: fileType == 'audio/mpeg' ? 'defaultAudioAttach' : "defaultAttach"
			};

			let lastMessage = messages[messages.length-1];

			setMessages([...messages, oNewMessage]);

			aoPromises.push(
				ApiCalls.sendAttachment(
					appConfig.host,
					client.phone,
					client.courseId,
					sText,
					appConfig.source,
					assessor.email,
					debugApp.developer,
					client.company ? client.company : appConfig.company,
					client.company ? client.company : appConfig.unit,
					oFile,
					sMessageUUID,
					assessor.name,
					assessor.lastName,
					typeof lastMessage != 'undefined' && lastMessage != null && typeof lastMessage?.twillio_phone != 'undefined' && lastMessage?.twillio_phone.length > 0 ? lastMessage?.twillio_phone : ''
				)
					.then(() => {
						//Do nothing
					})
					.catch(() => { })
			);
		});

		Promise.all(aoPromises)
			.then(() => {
				setAttachmentDialogOpen(false);
				setAttachmentDialogOpenInitial(false);
				setPasteFile([]);
			})
			.catch(() => {
				setPasteFile([]);
			});
	};

	// Revisamos si hay mensajes en menos de 24h para activar el botón de adjuntos
	useEffect(() => {
		disableAttachmentButton();
	}, [messages]);

	const disableAttachmentButton = () => {
		if (messages.length >= 1) {
			let aoFilterReceived = messages.filter(element => element.direction === "received");
			let oLastReceived = aoFilterReceived[aoFilterReceived.length - 1];
			let nowDate = new Date();
			if (aoFilterReceived.length >= 1) {
				let nDifferenceHours = (nowDate - new Date(oLastReceived.date_modified)) / (1000 * 3600);
				if (nDifferenceHours >= nLimitHours) {
					setDisabledButtonAttachment(true);
				} else {
					setDisabledButtonAttachment(false);
				}
			} else {
				setDisabledButtonAttachment(true);
			}
		} else {
			setDisabledButtonAttachment(true);
		}
	};

	// Funciones de cuando hacemos un paste de un adjunto abrimos el dialogo de adjuntos
	const onHandlePasteImage = e => {
		if (e.clipboardData.files.length === 1) {
			const fileObject = e.clipboardData.files[0];
			setPasteFile([fileObject]);
			setAttachmentDialogOpenInitial(true);
		}
	};

	const onCloseDialogInitial = () => {
		setAttachmentDialogOpenInitial(false);
		setPasteFile([]);
	};

	const returnDropzoneInitialFile = () => {
		if (pasteFile.length === 1 && !disabledButtonAttachment) {
			return (
				<ThemeProvider theme={appConfig.darkTheme ? theme : {}}>
					<DropzoneDialog
						id="attachmentDialogInitial"
						dropzoneText={t("attachment.dialog.text")}
						dialogTitle={t("attachment.dialog.title")}
						acceptedFiles={["image/*", "audio/*", ".pdf", ".vcf", ".txt"]}
						cancelButtonText={t("cancel")}
						submitButtonText={t("submit")}
						maxFileSize={16000000}
						initialFiles={pasteFile}
						open={attachmentDialogOpenInitial}
						onClose={onCloseDialogInitial}
						onSave={sendAttachments}
						showPreviews={true}
						showFileNamesInPreview={true}
						previewText={t("attachment.dialog.preview")}
						showAlerts={false}
						filesLimit={10}
					/>
				</ThemeProvider>
			);
		}
	};

	const notificationTimeoutRef = useRef(null);

	// Se utiliza para recargar la página automáticamente al detectar cambios de versión con getLastWebComponentVersion.
	useEffect(() => {
		let sendingMessage = false;
		if (inputMessage.length > 0) {
			sendingMessage = true;
			if (inputMessage.length > 1599) {
				setInputMessage(inputMessage.substring(0, 1599));
				setToastTitle("ssss");
				setToastMessage('Los caracteres máximos permitidos por Meta son 1600');
				setOpenNotification(true);

				if (notificationTimeoutRef.current !== null) {
					clearTimeout(notificationTimeoutRef.current);
				}

				// Configura un nuevo timeout
				notificationTimeoutRef.current = setTimeout(() => {
					setOpenNotification(false);
					notificationTimeoutRef.current = null; // Resetea la referencia
				}, 3000);

				console.log('Los caracteres máximos permitidos por Meta son 1600')
			}
		} else {
			sendingMessage = false;
			setSelectedDefaultMessage(null);
		}
		if (sendingMessage != updateComponent.isSendingMessage) {
			updateComponent.isSendingMessage = sendingMessage;
			setUpdateComponent(updateComponent);
		}
	}, [inputMessage])

	// Se utiliza para recargar la página automáticamente al detectar cambios de versión con getLastWebComponentVersion.
	useEffect(() => {
		let needUpdate = false;
		if (micRecording.isRecording != updateComponent.isRecordingAudio) {
			updateComponent.isRecordingAudio = micRecording.isRecording ?? false;
			needUpdate = true;
		}
		if (typeof micRecording.blobURL != 'undefined' && micRecording.blobURL.length > 0) {
			needUpdate = true;
			updateComponent.isRecordingAudio = true;
		}
		if (needUpdate) {
			setUpdateComponent(updateComponent);
		}
	}, [micRecording])

	return (
		<>
		<Snackbar open={openNotification} autoHideDuration={5000}>
			<Alert severity="error">{'Los caracteres máximos permitidos por Meta son 1600'}</Alert>
		</Snackbar>
		<DialogDefaultMessages 
			inputText={inputMessage} 
			setInputText={setInputMessage}
			openDialogDefaultMessages={openDialogDefaultMessages}
			setOpenDialogDefaultMessages={setOpenDialogDefaultMessages}
		/>
		<form className={appConfig.darkTheme ? classes.inputContainerDark : classes.inputContainer}>
			<EmojiButton client={client} setAnchorEl={setAnchorEl} />
			<PopoverEmoji
				popoverId={popoverId}
				open={open}
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				inputMessage={inputMessage}
				setInputMessage={setInputMessage}
			/>
			<InputBase
				onPaste={onHandlePasteImage}
				disabled={!client.phone || (!client.active && client.active !== undefined)}
				type="text"
				className={
					micRecording.blobURL
						? classes.inputContainerActiveMic
						: appConfig.darkTheme
							? classes.inputMessageDark
							: classes.inputMessage
				}
				placeholder={micRecording.blobURL ? "" : t("messages.type")}
				multiline={true}
				maxRows={4}
				value={inputMessage}
				style={{ fontSize: appConfig.size }}
				onChange={oEvent => setInputMessage(oEvent.target.value)}
				// TODO: onKeyPress is deprecated. This should be onKeyDown or onKeyUp. I don't really know.
				onKeyPress={oEvent =>
					oEvent.key === "Enter"
						? oEvent.shiftKey
							? ""
							: appConfig.scheduleScreen
								? goToScheduleMessages()
								: sendMessage(oEvent)
						: null
				}
			/>
			{micRecording.blobURL && (
				<audio className={classes.audio} src={micRecording.blobURL} controls="controls" />
			)}
			{messagesSchedule.length !== 0 && !appConfig.scheduleScreen && <ScheduleMessageButton />}
			<MessagesTemplateButton
				openDialogDefaultMessages={openDialogDefaultTemplates}
				setOpenDialogDefaultMessages={setOpenDialogDefaultTemplates}
			/>
			<DialogTemplateDefaultMessages
				openDialogDefaultMessages={openDialogDefaultTemplates}
				setOpenDialogDefaultMessages={setOpenDialogDefaultTemplates}
				setInputMessage={setInputMessage}
				setInputMicRecording={setMicRecording}
				client={client}
			/>
			{!micRecording.isRecording && !micRecording.blobURL && !appConfig.scheduleScreen && (
				<AttachmentButton
					client={client}
					disabledButtonAttachment={disabledButtonAttachment}
					setAttachmentDialogOpen={setAttachmentDialogOpen}
				/>
			)}
			{inputMessage === "" && !appConfig.scheduleScreen && (
				<MicButton
					client={client}
					Mp3Recorder={Mp3Recorder}
					micRecording={micRecording}
					setMicRecording={setMicRecording}
					disabledButtonAttachment={disabledButtonAttachment}
				/>
			)}
			{(micRecording.isRecording || micRecording.blobURL) && (
				<CancelMicButton
					micRecording={micRecording}
					setMicRecording={setMicRecording}
					Mp3Recorder={Mp3Recorder}
				/>
			)}
			{(micRecording.blobURL || inputMessage !== "") && !appConfig.scheduleScreen && (
				<Tooltip title={<Typography variant="body2">{t("send.tooltip")}</Typography>}>
					<IconButton
						disabled={!client.phone || (!client.active && client.active !== undefined)}
						component="span"
						onClick={sendMessage}
						onContextMenu={handleOpenPopover}
					>
						<Send />
					</IconButton>
				</Tooltip>
			)}

			{inputMessage !== "" && appConfig.scheduleScreen && (
				<Tooltip title={<Typography variant="body2">{t("send.tooltip")}</Typography>}>
					<IconButton
						disabled={!client.phone || (!client.active && client.active !== undefined)}
						component="span"
						onClick={goToScheduleMessages}
					>
						<Schedule />
					</IconButton>
				</Tooltip>
			)}
			<PopoverInputChat
				idOptionsInputChat={idOptionsInputChat}
				bOpenOptionsInputChat={bOpenOptionsInputChat}
				anchorElOptionsInputChat={anchorElOptionsInputChat}
				handleClosePopoverOptionsInputChat={handleClosePopoverOptionsInputChat}
				setOpenDialogSchedule={setOpenDialogSchedule}
			/>
			<DialogScheduleInputChat
				openDialogSchedule={openDialogSchedule}
				handleCloseDialogSchedule={handleCloseDialogSchedule}
				inputMessage={inputMessage}
				setInputMessage={setInputMessage}
				setCacheUsers={setCacheUsers}
			/>
			<DialogGlobalSearch />
			<ThemeProvider theme={appConfig.darkTheme ? theme : {}}>
				<DropzoneDialog
					dropzoneText={t("attachment.dialog.text")}
					dialogTitle={t("attachment.dialog.title")}
					acceptedFiles={["image/*", "audio/*", "application/*", ".pdf", ".vcf", ".txt"].concat(
						constants.mimeTypeApplication,
						constants.mimeTypeText,
						constants.mimeTypeImage,
						constants.mimeTypeVideo,
						constants.mimeTypeAudio
					)}
					onContextMenu={e => {
						e.preventDefault();
					}}
					cancelButtonText={t("cancel")}
					submitButtonText={t("submit")}
					maxFileSize={16000000}
					open={attachmentDialogOpen}
					onClose={() => setAttachmentDialogOpen(false)}
					onSave={sendAttachments}
					showPreviews={true}
					showFileNamesInPreview={true}
					previewText={t("attachment.dialog.preview")}
					showAlerts={false}
					filesLimit={10}
				></DropzoneDialog>
			</ThemeProvider>
			{returnDropzoneInitialFile()}
		</form>
		</>
	);
};

InputChat.propTypes = {
	setCacheUsers: PropTypes.func
};

export default InputChat;

const useStyles = makeStyles(() => ({
	inputMessage: {
		width: "100%",
		border: 0,
		backgroundColor: "#f4f7f9",
		color: "#000000",
		paddingLeft: "15px",
		resize: "none",
		height: "auto"
	},
	inputMessageDark: {
		width: "100%",
		border: 0,
		backgroundColor: "#3e3e42",
		paddingLeft: "15px",
		resize: "none",
		height: "auto"
	},
	inputContainer: {
		alignItems: "center",
		display: "flex",
		backgroundColor: "#f4f7f9",
		height: "auto",
		borderRadius: "0 0 10px 10px",
		width: "100%",
		marginBottom: 0
	},
	inputContainerDark: {
		alignItems: "center",
		display: "flex",
		backgroundColor: "#3e3e42",
		borderTop: "1px solid #2d2d30",
		height: "auto",
		borderRadius: "0 0 10px 10px",
		width: "100%",
		marginBottom: 0
	},
	inputContainerActiveMic: {
		alignItems: "center",
		display: "flex",
		backgroundColor: "#f4f7f9",
		height: "auto",
		borderRadius: "0 0 10px 10px",
		width: "0%"
	},
	micActiveIcon: {
		color: "#ff0000"
	},
	audio: {
		width: "100%"
	}
}));

import React, {useContext} from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Helper from "../../../../utils/Helper";
import {
  Check,
  Close,
  DeleteForever,
  DoneAll,
  LocalHospital,
  PhonelinkLock,
  SmsFailed,
  Update
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import AppContext from "../../../../AppContext";

const { constants } = Helper;




const Icon = ({ messageStatus, twillio_phone }) => {
  const { appConfig } = useContext(AppContext);
  
  const useStyles = makeStyles(() => ({
    messageStatusIcon: {
      alignSelf: "flex-end"
    },
    read: {
      color: "#42a5f5"
    }
  }));

  const classes = useStyles();
  const { t } = useTranslation();
  let iconTitle = t(messageStatus);
  try{
    iconTitle = t(`${messageStatus}.tooltip`);
    if (iconTitle.length < 1 || iconTitle == `${messageStatus}.tooltip`) {
      iconTitle = t(messageStatus);
    }
    if (
      messageStatus === constants.messageStatus.i_am_an_experiment
      || messageStatus === constants.messageStatus.requestPermissions
      || messageStatus === constants.messageStatus.sent
      || messageStatus === constants.messageStatus.send
    ) {
      iconTitle = `${iconTitle} ${twillio_phone}`
    }
  } catch(e) {
    iconTitle = t(messageStatus);
  }
  
  return (
    <Tooltip className={classes.messageStatusIcon} title={<Typography fontSize={appConfig.size-2}>{iconTitle}</Typography>} interactive>
      {messageStatus === constants.messageStatus.i_am_an_experiment ? (
        <LocalHospital color="error" />
      ) : messageStatus === constants.messageStatus.failed ? (
        <Close color="primary" />
      ) : messageStatus === constants.messageStatus.queued ? (
        <Update color="primary" />
      ) : messageStatus === constants.messageStatus.sending ? (
        <Update color="primary" />
      ) : messageStatus === constants.messageStatus.delivered ? (
        <DoneAll color="primary" />
      ) : messageStatus === constants.messageStatus.accepted ? (
        <Update color="primary" />
      ) : messageStatus === constants.messageStatus.undelivered ? (
        <Close color="primary" />
      ) : messageStatus === constants.messageStatus.send ? (
        <Check color="primary" />
      ) : messageStatus === constants.messageStatus.sent ? (
        <Check color="primary" />
      ) : messageStatus === constants.messageStatus.received ? (
        <DoneAll color="primary" />
      ) : messageStatus === constants.messageStatus.read ? (
        <DoneAll className={classes.read} />
      ) : messageStatus === constants.messageStatus.deleted ? (
        <DeleteForever color="primary" />
      ) : messageStatus === constants.messageStatus.requestPermissions ? (
        <PhonelinkLock color="error" />
      ) : (
        <div />
      )}
    </Tooltip>
  );
};

Icon.propTypes = {
  messageStatus: PropTypes.string,
  twillio_phone: PropTypes.string
};

export default Icon;
